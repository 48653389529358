<template>
  <v-layout class="fill-height">
    <div style="flex: none; width: 340px; height: 100%">
      <v-card class="custom-card-bg-1 pa-3 overflow-y-hidden" height="100%" width="100%">
        <v-layout class="fill-height overflow-y-hidden" column>
          <div v-if="model === 'Tree Counting'" class="pt-3" style="width: 100%; height: fit-content">
            <v-autocomplete
              v-model="image"
              :items="images"
              dense
              hide-details
              item-text="name"
              label="Select image"
              outlined
              placeholder="Select image"
              return-object
              @change="displayImage"
            >
              <template v-slot:item="data">
                <v-layout align-center class="py-2">
                  <div class="mr-3" style="width: 50px; height: 50px">
                    <v-img
                      v-if="data.item.tile_info.thumbnail_url"
                      :aspect-ratio="1"
                      :lazy-src="data.item.tile_info.thumbnail_url"
                      :src="data.item.tile_info.thumbnail_url"
                      alt="tbn"
                      height="auto"
                      width="50"
                    >
                      <template v-slot:placeholder>
                        <v-row align="center" class="fill-height ma-0" justify="center">
                          <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                  {{ data.item.name }}
                </v-layout>
              </template>
            </v-autocomplete>
          </div>
          <div class="pt-3" style="width: 100%; height: fit-content">
            <v-btn
              :disabled="(zoomLevel < 16 && model !== 'Tree Counting') || (model === 'Tree Counting' && !image)"
              :loading="loading"
              color="primary"
              small
              width="100%"
              @click="getData"
            >
              Auto detect
            </v-btn>
          </div>
          <div
            v-if="model !== 'Tree Counting' && ($refs.map && $refs.map.getZoom()) <= 16"
            class="pt-3"
            style="width: 100%; height: fit-content; text-align: end"
          >
            <span style="color: yellow; font-size: 12px">Zoom level must be >= 16</span>
          </div>
          <div v-if="model === 'Tree Counting'" class="pt-3" style="width: 100%; height: fit-content; text-align: end">
            <span style="color: yellow; font-size: 12px">Total feature: {{ count }}</span>
          </div>
          <v-layout class="pt-3 fill-height" column style="flex: 1; overflow-y: auto">
            <div
              v-for="(item, index) in features"
              v-if="model === 'Farm Boundary'"
              style="width: 100%; border-radius: 4px"
            >
              <v-list-item class="my-1 py-1 pl-2 pr-5 custom-card-bg-1">
                <v-layout align-center class="py-1">
                  <div style="min-width: 60px; height: 60px">
                    <MapPreview :id="'AOI' + index" :geojson="item" />
                  </div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title class="text-truncate pl-3" v-bind="attrs" v-on="on">
                        {{ 'Object_' + index }}
                      </v-list-item-title>
                    </template>
                    <span>{{ 'Object_' + index }}</span>
                  </v-tooltip>
                </v-layout>

                <v-list-item-action>
                  <v-layout justify-end style="width: 100px">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" x-small @click="highLightLayer(item)">
                          <CustomIcon icon="gps" />
                        </v-btn>
                      </template>
                      <span>Zoom to</span>
                    </v-tooltip>
                  </v-layout>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-layout>
        </v-layout>
      </v-card>
    </div>
    <v-layout class="pl-4" style="flex: 1">
      <v-card class="custom-card-bg-1" height="100%" width="100%">
        <v-layout class="fill-height">
          <Map
            ref="map"
            :current-layers.sync="layers"
            @formData="data => (screenData = data)"
            @zoomLevel="zoomLv => (zoomLevel = zoomLv)"
          />
        </v-layout>
      </v-card>
    </v-layout>
  </v-layout>
</template>
<script>
import Map from '@/components/Map.vue'
import BorderArrow from '@/components/BorderArrow.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import { detectFarmBoundary } from '@/api/interactive-api'
import sleep from '@/utils/sleep'
import bbox from '@turf/bbox'
import utils from '@/utils/genUUID'
import MapPreview from '@/components/MapPreview.vue'

export default {
  components: { CustomIcon, BorderArrow, Map, MapPreview },
  data() {
    return {
      count: 0,
      zoomLevel: 0,
      layers: [],
      loading: false,
      features: [],
      models: ['Farm Boundary', 'Tree Counting'],
      screenData: undefined,
      images: [
        {
          id: 1,
          name: 'SKYSAT-2022-01',
          tile_info: {
            bbox: [116.153625000651, -2.75053590611536, 116.173546963312, -2.7306340031285],
            type: 'images',
            thumbnail_url:
              'https://platform.eofactory.ai/py/tile/-1/-1/-1/multiband?rel_path=/data/skysat/MOS_05012022_SKYSAT.tif&expression=b3;b2;b1&nodata=0&method=stretch_to_minmax&minmax=W3sibWF4IjoxNzYsIm1pbiI6ODB9LHsibWF4IjoxODksIm1pbiI6MTE1fSx7Im1heCI6MTc0LCJtaW4iOjExOX1d',
            tile_url:
              'https://platform.eofactory.ai/py/tile/{z}/{x}/{y}/multiband?rel_path=/data/skysat/MOS_05012022_SKYSAT.tif&expression=b3;b2;b1&nodata=0&method=stretch_to_minmax&minmax=W3sibWF4IjoxNzYsIm1pbiI6ODB9LHsibWF4IjoxODksIm1pbiI6MTE1fSx7Im1heCI6MTc0LCJtaW4iOjExOX1d',
          },
        },
        {
          id: 2,
          name: 'SKYSAT-2023-04',
          tile_info: {
            bbox: [116.153625000651, -2.75053590611536, 116.173546963312, -2.7306340031285],
            type: 'images',
            thumbnail_url:
              'https://platform.eofactory.ai/py/tile/-1/-1/-1/multiband?rel_path=/data/skysat/MOS_30042023_SKYSAT.tif&expression=b3;b2;b1&nodata=0&method=stretch_to_minmax&minmax=W3sibWF4IjoxNjAsIm1pbiI6MTIwfSx7Im1heCI6MTk0LCJtaW4iOjE1Nn0seyJtYXgiOjE3MywibWluIjoxNDh9XQ==',
            tile_url:
              'https://platform.eofactory.ai/py/tile/{z}/{x}/{y}/multiband?rel_path=/data/skysat/MOS_30042023_SKYSAT.tif&expression=b3;b2;b1&nodata=0&method=stretch_to_minmax&minmax=W3sibWF4IjoxNjAsIm1pbiI6MTIwfSx7Im1heCI6MTk0LCJtaW4iOjE1Nn0seyJtYXgiOjE3MywibWluIjoxNDh9XQ==',
          },
        },
      ],
      image: undefined,
      vectors: [
        {
          id: 1,
          name: 'SKYSAT-2022-01-tree_counting',
          tile_info: {
            bbox: [116.153625000651, -2.75053590611536, 116.173546963312, -2.7306340031285],
            type: 'vector',
            tile_url:
              'https://platform.eofactory.ai/py/vector-tile/{z}/{x}/{y}?vector_uuid=1&table_name=raw_data.tree_05012022_point',
            styles: {
              type: 'circle',
            },
          },
        },
        {
          id: 2,
          name: 'SKYSAT-2023-04-tree_counting',
          tile_info: {
            bbox: [116.153625000651, -2.75053590611536, 116.173546963312, -2.7306340031285],
            type: 'vector',
            tile_url:
              'https://platform.eofactory.ai/py/vector-tile/{z}/{x}/{y}?vector_uuid=1&table_name=raw_data.tree_30042023_point',
            styles: {
              type: 'circle',
            },
          },
        },
      ],
    }
  },
  props: {
    model: { type: String, default: 'Farm Boundary' },
  },
  watch: {
    model() {
      this.features = []
    },
  },
  computed: {},
  methods: {
    async getData() {
      switch (this.model) {
        case 'Farm Boundary':
          await this.detectFarm()
          break
        case 'Tree Counting':
          await this.addVectorToMap()
          break
      }
    },
    async detectFarm() {
      try {
        this.loading = true
        this.$refs.map.removeAllLayer()
        this.$refs.map.getMapFormData()
        await sleep(500)
        const res = await detectFarmBoundary(this.screenData)
        this.$refs.map.addGeoJsonToMap('farm', res.data, 'red', utils.getUUID(), 'line', true)
        let bound = bbox(res.data)
        this.$refs.map.submitZoom(bound)
        this.features = res.data.features
      } catch (e) {
        this.features = []
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    highLightLayer(feature) {
      this.$refs.map.addHighlightLayer(feature.geometry)
    },
    displayImage() {
      this.$refs.map.removeAllLayer()
      this.images.forEach(item => {
        item.display = false
      })
      this.image.display = true
      let image = {
        id: this.image.name,
        data: this.image.tile_info,
        layerType: 'Raster_Image',
        name: this.image.name,
        tiles: [this.image.tile_info.tile_url],
        bounds: this.image.tile_info.bbox,
      }
      this.$refs.map.addImageToMap(image)
      this.$refs.map.submitZoom(this.image.tile_info.bbox)
    },
    async addVectorToMap() {
      let index = this.images.findIndex(val => val.id === this.image.id)
      this.loading = true
      this.count = 0
      await sleep(10000)
      this.$refs.map.addVectorTiles({
        display: true,
        data: this.vectors[index].tile_info,
        tiles: [this.vectors[index].tile_info.tile_url],
        bounds: this.vectors[index].tile_info.bbox,
        layerName: 'default',
        name: this.vectors[index].name,
        id: this.vectors[index].name,
        type: this.vectors[index].tile_info.styles.type,
      })
      if (index === 0) this.count = 34963
      else this.count = 37255
      this.loading = false
    },
  },
}
</script>

<style scoped></style>
