<template>
  <v-layout class="fill-height">
    <AIModels model="Farm Boundary" />
  </v-layout>
</template>
<script>
import Map from '@/components/Map.vue'
import BorderArrow from '@/components/BorderArrow.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import { detectFarmBoundary } from '@/api/interactive-api'
import sleep from '@/utils/sleep'
import bbox from '@turf/bbox'
import utils from '@/utils/genUUID'
import MapPreview from '@/components/MapPreview.vue'
import AIModels from '@/views/interactiveAI/AIModels.vue'

export default {
  components: { AIModels },
}
</script>

<style scoped></style>
